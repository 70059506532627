.footer{
  border-top:1px solid $black;
  padding:rem-calc(30) 0;
  font-size:rem-calc(12);
  a{
    color:$black;
  }
  nav{
    a{
      text-transform:uppercase;
    }
  }
  i{
    color:$gray-400;
    font-size:rem-calc(16);
  }
  .list-inline-item:not(:last-child){
    margin-right:2rem;
  }
}

.footer__copyright{
  color:$gray-400;
  padding-bottom:rem-calc(30);
}