.section--spieltermine{
  h2{
    font-size:rem-calc(20);
    font-weight:$weight-extrabold;
    margin:0;
  }

  .nav-item{
    position:relative;
    border:none;
    color:$black;
    text-transform:uppercase;
    display:inline-block;
    span{
      position:relative;
      z-index:2;
    }
    &:before{
      transition:all 0.2s ease-in-out 0s;
      background:$black;
      color:$white;
      text-decoration:none;
      content:"";
      opacity:0;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      transform:skewX(-20deg);
      z-index:1;
    }
    &.active{
      span{
        color:$white;
      }
      &:before{
        opacity:1;
      }
    }
  }



}