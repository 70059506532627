
article{

  &.single{
    h1{
      font-weight:$weight-extrabold;
    }

    h2{
      font-size:rem-calc(30);
      line-height:em-calc(36,30);
      font-weight:$weight-extrabold;
      margin:1.5em 0 1em;
    }

    h3{
      font-size:rem-calc(20);
      line-height:em-calc(24,20);
      font-weight:$weight-extrabold;
      margin:1em 0 1em;
    }

    h4{
      font-size:rem-calc(16);
      line-height:em-calc(22,16);
      font-weight:$weight-bold;
      margin:1em 0 1em;
    }

    header{
      margin-bottom:1em;
      + p{
        font-size:rem-calc(20);
        line-height:em-calc(30,20);
      }
    }



    .wp-block-image{
      position:relative;
      left:-36%;
      margin-top:2em;
      @include corner-left;

      img{
        max-width:136%;
        height:auto;


      }

    }

  }
}
