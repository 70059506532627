.wp-block-image{
  img{
    height:auto;
  }
  figcaption{
    margin:0 0 rem-calc(16) !important;
    padding:5px;
    background:$green;
    text-align:center;
    color:$white;
    font-size:rem-calc(14);
  }
}
