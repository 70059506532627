.section--termine{
  @include corners-inside();
  background:$white;
}
.bg--greenelement{
  position:relative;
  &:before{
    content:"";
    position:absolute;
    left:0;
    width:100vw;
    top:20%;
    height:60%;
    background:$green;
    z-index:-1;

  }
}
.list__termine{



  @include list-unstyled;

  li{
    time{
      font-weight:$weight-bold;
    }
    h2{
      margin-top:rem-calc(7);
      font-size:rem-calc(14);
      line-height:em-calc(18,14);
      font-weight:$weight-regular;
    }
    small{
      font-size:rem-calc(12);
      color:$gray-400;
      display:block;
    }
    .location{
      display:block;
      font-size:rem-calc(12);
    }
    + li{
      padding-top:rem-calc(16);
      margin-top:rem-calc(16);
      border-top:1px solid $gray-200;
    }
  }
}

.list__termine--empty{
  display:flex;
  flex-direction:column;
  justify-content:center;
  height:100%;
}
