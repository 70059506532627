.section--actionbuttons{
  //margin-top:rem-calc(30);
  a{

    margin:auto;
    position:relative;
    height:94px;
    display:flex;
    align-items:center;
    max-width:210px;
    &:hover{
      text-decoration:none;
      span{
        color:$green;
      }


    }
    span{
      transition:all 0.2s linear 0s;
      z-index:1;
      position:relative;
      font-weight:$weight-extrabold;
      font-size:rem-calc(14);
      white-space:nowrap;
      padding-inline:rem-calc(20);
      text-transform:uppercase;
      color:$black;
      display:flex;
      align-items:center;
      i{
        font-size:2rem;
      }
    }
    &:before{
      position:absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;
      content:"";
      z-index:0;
    }
    &:nth-of-type(odd){
      &:before{
        background:url(img/actionbutton-odd.png) center center/contain no-repeat;
      }


    }
    &:nth-of-type(even){
      &:before {
        top:-10px;
        bottom:10px;
        background:url(img/actionbutton-even.png) center center/contain no-repeat;
      }
    }

  }
}
