@use "sass:math";

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number , ($number * 0 + 1));
  }

  @return $number;
}

@function rem-calc($size) {
  @return #{math.div($size, 16)}rem;
}

@function em-calc($line-height, $font-size){
  @return #{math.div($line-height,$font-size)}em;
}

@mixin responsiveborder{
  border-top:3px solid black;
  &:before{
    position:fixed;
    top:0;
    left:0;
    content:"XS";
    background:black;
    color:white;
    z-index:100000;
  }
  &.admin-bar{
    &:before{
      top:35px;
      @include media-breakpoint-down(sm){
        top:48px;
      }
    }

  }
  @include media-breakpoint-only(sm){
    border-top-color:$blue;
    &:before{
      content:"SM";
      background:$blue;
    }
  }
  @include media-breakpoint-only(md){
    border-top-color:$orange;
    &:before{
      background:$orange;
      content:"MD";
    }
  }
  @include media-breakpoint-only(lg){
    border-top-color:$green;
    &:before{
      background:$green;
      content:"LG";
    }
  }
  @include media-breakpoint-only(xl){
    border-top-color:$yellow;
    &:before{
      background:$yellow;
      content:"XL";
    }
  }
}


@mixin corners(){
  position:relative;
  &:before{
    content:"";
    width:75px;
    height:75px;
    border-left:4px solid $green;
    border-top:4px solid $green;
    position:absolute;
    top:rem-calc(-10);
    left:rem-calc(-10);
    z-index:-1;

  }
  &:after{
    content:"";
    width:75px;
    height:75px;
    border-right:4px solid $green;
    border-bottom:4px solid $green;
    position:absolute;
    bottom:rem-calc(-10);
    right:rem-calc(-10);
    z-index:-1;
  }
}

@mixin corner-left(){
  position:relative;
  &:before{
    content:"";
    width:380px;
    height:380px;
    border-left:15px solid $green;
    border-top:15px solid $green;
    position:absolute;
    top:rem-calc(-15);
    left:rem-calc(-15);
    z-index:-1;
  }
}

@mixin corners-inside(){
  @include corners();
  padding:rem-calc(16);
  &:before{
    top:0;
    left:0;
    z-index:-1;
  }
  &:after{
    bottom:0;
    right:0;
    z-index:-1;
  }
}
