.topnews{
  position:relative;
  header{
    @include corners;
    position:absolute;
    width:60%;
    height:auto;
    background:$green;
    color:$white;
    left:0;
    bottom:rem-calc(-20);
    padding:rem-calc(30);
    @include media-breakpoint-down(md){
      padding:rem-calc(24);
      width:80%;
      bottom:-3em;
    }
    h1{
      font-size:rem-calc(38);
      font-weight:$weight-extrabold;
      @include media-breakpoint-down(md){
        font-size:rem-calc(20);
      }

    }
    a{
      text-transform:uppercase;
      font-size:rem-calc(12);
      font-weight:$weight-bold;
      color:$white;
      display:inline-flex;
      align-items:center;
      i{
        margin-left:5px;
      }
      &:hover{
        text-decoration:none;
      }
    }
  }
  img{
    @include media-breakpoint-down(md){
      width:100%;
    }
  }
}
