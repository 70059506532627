.widget{
  margin-bottom:2em;

}

.widget-title{
  font-size:rem-calc(14);
  text-transform:uppercase;
  font-weight:$weight-extrabold;
}

.widget_categories{
  ul{
    margin:0;
    padding:0;
    li{
      display:inline-block;
      position:relative;
      padding:rem-calc(6) rem-calc(10);
      margin-bottom:rem-calc(10);
      margin-right:rem-calc(10);
      margin-left:rem-calc(5);
      a{
        color:$white;
        font-size:rem-calc(12);
        line-height:1em;
        display:block;
      }
      &:before{
        content:"";
        background:$green;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:-1;
        transform:skewX(-25deg);
      }
    }
  }
}