.newscard{
  position:relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  width:rem-calc(230);
  height:rem-calc(280);
  background:$white;
  section{
    padding:rem-calc(10) rem-calc(20);
  }

  img{
    @extend .img-fluid;
  }
  h2{
    font-size:rem-calc(20);
    line-height:em-calc(28,20);
    font-weight:$weight-bold;

    overflow:hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

  }
  time{
    font-size:rem-calc(12);
    color:$gray-400;
  }
  a{
    font-size:rem-calc(12);
  }
}
