.container-sponsor{
  position:relative;
  @include corners;

  h2{
    color:$primary;
    position:absolute;
    top:rem-calc(-16);
    left:rem-calc(80);
    text-transform:uppercase;
    font-size:rem-calc(14);
    font-weight:$weight-bold;
  }
}
