.header{
  i{
    color:$gray-400;
  }
  .col-12{
    margin-top:rem-calc(18);
  }
  .menu__top{
    a{
      font-size:rem-calc(14);
      color:$white !important;
      font-weight:$weight-bold;
    }
  }
  ul{
    margin-bottom:0;
  }
  .social-media{

    .list-inline-item:not(:last-child){
      margin-right:1rem;
    }
  }

  .dropdown-menu{
    padding:0;
    background:#f2f2f2;
    border:none;
    border-bottom:2px solid $green;
    border-radius:0;

    a{
      color:$black !important;
      font-weight:$weight-bold;
      small{
        font-weight:$weight-regular;
      }
      + a{
        border-top:2px solid $white;
      }
      &:hover{

      }
    }
    &:before{
      position: absolute;
      top:-10px;
      left:20px;
      content:"";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #f2f2f2;
    }
  }
}

.list--teams{
  a{
    font-size:rem-calc(12);
    text-transform:uppercase;
    color:$gray-400;
    padding:rem-calc(6) rem-calc(10);
    position:relative;
  }
  li{
    > a{


      span{
        z-index:2;
        position:relative;
        transition:all 0.2s ease-in-out 0s;
      }
      &:before{
        transition:all 0.2s ease-in-out 0s;
        background:$green;
        color:$white;
        text-decoration:none;
        opacity:0;
        content:"";
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        transform:skewX(-20deg);
        z-index:1;
      }
      &:hover{
        cursor:pointer;
        text-decoration:none;
        span{
          color:$white;

        }
        &:before{
          opacity:1;
        }
      }
    }
  }


}