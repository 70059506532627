.section--premium, .widget_bf_partner_widget,.widget_bf_partner_slider_widget{
  position:relative;
  @include corners;
  min-height:70px;
  display:flex;
  top:10px;
  align-items:center;
  justify-content:center;
  a{
    display:block;
  }

  h2{
    position:absolute;
    top:rem-calc(-16);
    left:rem-calc(80);
    text-transform:uppercase;
    font-size:rem-calc(14);
    font-weight:$weight-bold;
  }
}

@include media-breakpoint-down(md){
  aside{
    section:first-child.section--premium{
      margin-top:75px;
    }
  }
}

.widget_bf_partner_slider_widget{
  @include corners-inside;
  &:before{
    border-color:$black;
  }
  &:after{
    border-color:$black;
  }
  h2{
    color:$black;
    top:rem-calc(-6);
    white-space:nowrap;
  }
  img{
    padding-top:12px;
    padding-bottom:12px;
  }
}

.widget_bf_partner_widget{
  @include corners-inside;
  &:before{
    border-color:$black;
  }
  &:after{
    border-color:$black;
  }
  h2{
    color:$black;
    top:rem-calc(-6);
  }
  img{
    padding-top:12px;
    padding-bottom:12px;
  }
}
