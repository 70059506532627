.person{
  h2{
    font-size:rem-calc(16);
    font-weight:$font-weight-bold;
    margin-bottom:rem-calc(3);
  }
  h3{
    text-transform:uppercase;
    font-size:rem-calc(14);
  }
  p{
    font-size:rem-calc(14);
    margin-top:rem-calc(16);
  }
}