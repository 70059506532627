@use "sass:math";
.wp-block-media-text{
  margin-bottom:rem-calc(60);
  position:relative;
  background:$white;
  img{
    width:math.div(100%,  120);
    height:auto;
  }


}

.wp-block-media-text__content{
  position:absolute;
  right:0;
  bottom:0;
  background:$gray-900;
  font-size:rem-calc(19);
  line-height:em-calc(34,19);
  color:$gray-500;
  padding:rem-calc(60);
  width:math.div(100%, 48);
  font-style:italic;
}
