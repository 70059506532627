body{
 // @include responsiveborder;
  overflow-x:hidden;
}
main{
  padding:rem-calc(40) 0;
}
.btn-superrounded{
 // padding:rem-calc(4) rem-calc(12);
  border-radius:rem-calc(17);
  font-size:rem-calc(12);
  border-width:2px;
}



.icon--more{
  font-weight:$weight-bold;
  &:after{
    @extend .icon;
    @extend .icon-pfeil_link, ::before;
    font-size:rem-calc(9) !important;
    margin-left:5px;
    color:$green;
  }
}

.link--more.link--more{

  text-transform:uppercase;
  font-size:rem-calc(12);
  @extend .icon--more;
}

.color--green{
  color:$green;
}

.corners__header{
  position:absolute;
  top:rem-calc(-6);
  left:rem-calc(80);
  color:$green;
  text-transform:uppercase;
  font-size:rem-calc(14);
  font-weight:$weight-bold;
}

.corners__footer{
  position:absolute;
  bottom:rem-calc(-6);
  left:rem-calc(14);
  text-transform:uppercase;
  font-size:rem-calc(14);
  font-weight:$weight-bold;
}

.team{
  //white-space:nowrap;
}

.team--flames{
  color:$green;
  font-weight:bolder;
}


p{
  line-height:em-calc(22,16);
}

.table-vcentered{
  td{
    vertical-align:middle;
  }
}

.img-hoverable{
  transition:all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  &.img-hovered{
    box-shadow:1px 1px 5px black;
    transform:scale(10);
  }
}
