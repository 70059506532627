
@font-face {
  font-family: 'basketflames';
  src: url('fonts/basketflames.eot');
  src: url('fonts/basketflames.eot') format('embedded-opentype'),
  url('fonts/basketflames.woff2') format('woff2'),
  url('fonts/basketflames.woff') format('woff'),
  url('fonts/basketflames.ttf') format('truetype'),
  url('fonts/basketflames.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'basketflames';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

$icon-1_nav_pfeil_li: unicode(ea01);
$icon-2_nav_pfeil_re: unicode(ea02);
$icon-clsoe_menu_mobile: unicode(ea03);
$icon-ecke_oben: unicode(ea04);
$icon-ecke_unten: unicode(ea06);
$icon-facebook: unicode(ea05);
$icon-google_plus: unicode(ea07);
$icon-instagram: unicode(ea08);
$icon-menu_mobile: unicode(ea09);
$icon-mitglied_werden: unicode(ea0a);
$icon-nav_pfeil_li: unicode(ea0b);
$icon-nav_pfeil_re: unicode(ea0c);
$icon-pfeil_link: unicode(ea0d);
$icon-pin: unicode(ea0e);
$icon-shop: unicode(ea0f);
$icon-sponsor_werden: unicode(ea10);
$icon-twitter: unicode(ea11);
$icon-youtube: unicode(ea12);

.icon-1_nav_pfeil_li::before {
  content: $icon-1_nav_pfeil_li;
}

.icon-2_nav_pfeil_re::before {
  content: $icon-2_nav_pfeil_re;
}

.icon-clsoe_menu_mobile::before {
  content: $icon-clsoe_menu_mobile;
}

.icon-ecke_oben::before {
  content: $icon-ecke_oben;
}

.icon-ecke_unten::before {
  content: $icon-ecke_unten;
}

.icon-facebook::before {
  content: $icon-facebook;
}

.icon-google_plus::before {
  content: $icon-google_plus;
}

.icon-instagram::before {
  content: $icon-instagram;
}

.icon-menu_mobile::before {
  content: $icon-menu_mobile;
}

.icon-mitglied_werden::before {
  content: $icon-mitglied_werden;
}

.icon-nav_pfeil_li::before {
  content: $icon-nav_pfeil_li;
}

.icon-nav_pfeil_re::before {
  content: $icon-nav_pfeil_re;
}

.icon-pfeil_link::before {
  content: $icon-pfeil_link;
}

.icon-pin::before {
  content: $icon-pin;
}

.icon-shop::before {
  content: $icon-shop;
}

.icon-sponsor_werden::before {
  content: $icon-sponsor_werden;
}

.icon-twitter::before {
  content: $icon-twitter;
}

.icon-youtube::before {
  content: $icon-youtube;
}

