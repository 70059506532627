.article--page, .category{
  header{
    h1{
      font-size:rem-calc(38);
      line-height:em-calc(44,38);
      font-weight:900;

    }

    margin-bottom:rem-calc(40);
  }

}

.widget_bf_partner_widget{
  @include media-breakpoint-down(md){
    margin-top:rem-calc(75);
  }
}
