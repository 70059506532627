.table{

  width:100%;
  caption{
    font-size:rem-calc(20);
    font-weight:900;
    caption-side: top;
    color:$black;
  }

  td, & th{
    font-size:rem-calc(14);
    padding:rem-calc(8) rem-calc(16);
    font-weight:$weight-semibold;
    &:last-of-type{
      text-align:right;
    }
  }

  td{
    border-top:1px solid $gray-200;
  }
  thead{
    color:$gray-400;
    border-top:2px solid $green;
  }

}