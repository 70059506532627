.container--newsslider{
  margin-top:rem-calc(70);
  position:relative;
  margin-bottom:rem-calc(50);
  @include media-breakpoint-down(md){
    margin-top:rem-calc(20);
  }
  .slide{
    padding:rem-calc(30) 0;
  }
  &:before{
    content:"";
    position:absolute;
    left:50%;
    top:0;
    bottom:0;
    background:$gray-800;
    height:100%;
    width:50%;
    transform:translateX(-50%);
    z-index:0;
    @include media-breakpoint-down(md){
      transform:translateX(-10%);
      left:20%;
      width:80%;
    }
  }

}

.link--news{
  color:$white;
  display:block;
  position:relative;
  top:rem-calc(-10);
  left:50%;
  z-index:2;
}

