@charset "UTF-8";



@function slick-font-url($url) {
    @return url("../fonts/" + $url);
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff url("img/ajax-loader.gif") center center no-repeat;
    }
}

.slick-slide{
    margin:10px;
}

/* Icons */
@font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    color: transparent;
    top:0;
    transform: translate(0, -50%);
    padding: 0;
        z-index:10;
    background:$gray-200;
    border:none;
    outline: none;
    transition:all 0.2s linear 0s;
    width:rem-calc(25);
    height:rem-calc(25);
    opacity:0.5;
    &:hover, &:focus {
        outline: none;
        transform:translate(0, -50%) scale(1.1);
        color: $green;
        &:before {
            opacity: 1;
            background:$green;
            color:$white;
        }
    }
    &.slick-disabled:before {
        opacity: 0;
    }
    &:before {
        width:rem-calc(25);
        height:rem-calc(25);
        color:$gray-600;
        @extend .icon;
        @extend .icon-nav_pfeil_li, ::before;
        display:block;
        z-index:10;
        font-size:rem-calc(12);
        line-height:em-calc(25,12);
    }
}

.slick-prev {
    left: 0;

}

.slick-next {
    left:rem-calc(30);
    &:before{
        @extend .icon-nav_pfeil_re, ::before;
    }

}



/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -30px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        margin: 0 rem-calc(9);
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            display: block;
            height: 8px;
            width: 8px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            border-radius:50%;
            background:rgba($gray-100, 0.5);
            transition:all 0.2s linear 0s;
            transform:none;
        }
        &.slick-active{
            button{
                background:$red;
                transform:scale(1.5);
            }
        }
    }
}
